import { sumBy } from 'lodash'
import { Http } from '@/request'
import { mapState } from 'vuex';
import { Toast } from 'vant';
import { getLipStickList } from '@/service/lipGuoqingTemp'
import { getFormatsCount, setFormats, getFormats } from '@/utils/lipGuoqingTemp'
import CustomStepper from '@/components/common/custom-stepper/index.vue'

function scroll (selector) {
  const el = document.querySelector(selector)
  if (el) {
    el.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    })
  }
}

/**
 * 选择赠品
 */
export default {
  name: 'choose-lip',
  components: {
    CustomStepper,
  },

  data () {
    return {
      gifts: [],
      // demandGiftCount: 0,
    }
  },

  computed: {
    ...mapState({
      brandId: state => state.global.brandId,
      // 赠品要选的数量
      demandGiftCount: state => state.preorder.lipCount || 0
    }),

    // 选择赠品的步长
    customStep () {
      const { totalGiftCount, demandGiftCount } = this
      const remain = demandGiftCount - totalGiftCount
      if (remain >= 5) {
        return 5
      }
      return 1
    },

    totalGiftCount () {
      const { gifts } = this
      return getFormatsCount(gifts)
    },

    giftOK () {
      return this.totalGiftCount === this.demandGiftCount
    },

    done () {
      return this.giftOK
    }
  },

  methods: {
    onConfirm () {
      if (!this.giftOK) {
        Toast('亲，您还有赠品没挑选完哦～')
        scroll('.gift-wrapper')
        return
      }
      Toast(`选择口红成功！`)
      let experiences = getFormats(this.gifts)
      experiences = experiences.filter(exp => exp.count > 0)
      this.$store.commit('preorder/setLipExperienceList', experiences)
      this.$router.replace({
        name: 'pre-order',
        query: { from: 'cart' },
        params: { experiences }
      })
    },

    onOverGift () {
      if (this.giftOK) {
        Toast(`亲，赠品最多只能选${this.demandGiftCount}套哦～`)
      }
    },
  },

  async created () {
    /*     const {
          demandGiftCount,
        } = this.$route.query
        this.demandGiftCount = demandGiftCount ? Number(demandGiftCount) : 0 */
    const lips = await getLipStickList()
    setFormats(lips)
    this.gifts = lips || []
  }
}
